




























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ScSelect, ScLoader } from 'schmalz-components';
import axios from 'axios';
import countries from '@/assets/countries.json';
import { SalesPoint } from '@/models/SalesPoint.model';
import { Country } from '@/models/Country.model';

const { VUE_APP_API_BASE: API_BASE } = process.env;

@Component({
    components: {
        ScSelect,
        ScLoader,
    },
})
export default class FindContact extends Vue {
    salesPoints: SalesPoint[] = [];
    options = countries.sort((a: Country, b: Country) => a.label.localeCompare(b.label));
    selectedCountry: string | null = null;
    isLoading = false;
    isError = false;

    @Watch('selectedCountry')
    async changeCountry(country: string): Promise<void> {
        this.isError = false;
        this.salesPoints = [];
        if (country) {
            this.isLoading = true;
            try {
                const { data } = await axios.get<SalesPoint[]>(`${API_BASE}/locations`, {
                    params: {
                        country: country,
                    },
                });
                this.salesPoints = data;
            } catch (error) {
                console.error(error);
                this.isError = true;
                this.selectedCountry = null;
            } finally {
                this.isLoading = false;
            }
        }
    }

    changeLineBreaks(s: string): string {
        return s.replace(/, /g, '<br>');
    }

    getHostname(url: string): string {
        return new URL(url).hostname;
    }
}
