




























import { Component, Vue } from 'vue-property-decorator';
import { ScCard } from 'schmalz-components';
import FindContact from './components/FindContact.vue';
import GetInContact from './components/GetInContact.vue';
import Download from './components/Download.vue';

@Component({
    components: {
        ScCard,
        GetInContact,
        FindContact,
        Download,
    },
})
export default class App extends Vue {}
