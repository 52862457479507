










































































































































































































/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Mixins } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, numeric, minLength, email } from 'vuelidate/lib/validators';
import {
    ScInput,
    ScSelect,
    ScRadio,
    ScCheckbox,
    ScButton,
    ScInputGroup,
    ScTextArea,
    ScAlert,
} from 'schmalz-components';
import countriesJson from '@/assets/countries.json';
import { Country } from '@/models/Country.model';
import { ContactRequest } from '@/models/ContactRequest.model';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

const { VUE_APP_GRECAPTCHA_SITEKEY, VUE_APP_API_BASE: API_BASE } = process.env;

@Component({
    components: {
        ScInput,
        ScSelect,
        ScRadio,
        ScCheckbox,
        ScButton,
        ScInputGroup,
        ScTextArea,
        ScAlert,
        VueRecaptcha,
    },
    validations: {
        note: { required },
        company: { required },
        street: { required },
        postal: { required, numeric, minLength: minLength(3) },
        city: { required },
        country: { required },
        state: { required: requiredIf((vm) => vm.isStateFieldRequired) },
        salutation: { required },
        firstname: { required },
        lastname: { required },
        telephone: { required, valid: (value) => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(value) },
        email: { required, email },
        privacyterms: { accept: (value) => value === true },
        recaptchaToken: { required },
    },
})
export default class GetInContact extends Mixins(validationMixin) {
    /* BEGIN DATA */
    note: string | null = null;
    company: string | null = null;
    street: string | null = null;
    postal: string | null = null;
    city: string | null = null;
    country: string | null = null;
    state: string | null = null;
    salutation: string | null = null;
    firstname: string | null = null;
    lastname: string | null = null;
    telephone: string | null = null;
    email: string | null = null;
    privacyterms = false;
    contactMail = false;
    contactPhone = false;
    recaptchaToken: string | null = null;
    /* END DATA */

    RECAPTCHA_SITEKEY = VUE_APP_GRECAPTCHA_SITEKEY;

    countries = countriesJson.sort((a: Country, b: Country) => a.label.localeCompare(b.label));
    selected = null;

    submitted = false;
    isLoading = false;
    isSuccess = false;
    isError = false;

    get isStateFieldRequired(): boolean {
        return this.country == 'us' || this.country == 'ca';
    }

    async onSubmit(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isSuccess = false;
        this.isError = false;

        this.$v.$touch();
        this.submitted = true;
        if (this.$v.$invalid) {
            return;
        }

        try {
            this.isLoading = true;
            const contactRequest: ContactRequest = {
                message: this.note!,
                company: this.company!,
                street: this.street!,
                postalCode: this.postal!,
                city: this.city!,
                country: this.country!,
                state: this.state!,
                salutation: this.salutation!,
                firstname: this.firstname!,
                lastname: this.lastname!,
                telephone: this.telephone!,
                mail: this.email!,
                contactMail: this.contactMail,
                contactPhone: this.contactPhone,
                token: this.recaptchaToken!,
            };

            await axios.post(`${API_BASE}/contactrequest`, contactRequest);

            this.isSuccess = true;
            this.submitted = false;
            this.clearForm();
        } catch (error) {
            console.log(error);
            this.isError = true;
            (this.$refs.captcha as VueRecaptcha).reset();
        } finally {
            this.isLoading = false;
        }
    }

    onVerify(recaptchaToken: string): void {
        this.recaptchaToken = recaptchaToken;
    }

    clearForm(): void {
        this.note = null;
        this.company = null;
        this.street = null;
        this.postal = null;
        this.city = null;
        this.country = null;
        this.state = null;
        this.salutation = null;
        this.firstname = null;
        this.lastname = null;
        this.telephone = null;
        this.email = null;
        this.contactMail = false;
        this.contactPhone = false;
        this.recaptchaToken = null;
        this.privacyterms = false;

        (this.$refs.captcha as VueRecaptcha).reset();
        this.submitted = false;
    }
}
